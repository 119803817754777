import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../../../components/seo'
import ContentHeroNew from '../../../components/content-hero-new'
import Container from '../../../components/container'
import { infoContainer } from './index.module.scss'

const IndexPage = () => (
  <div className={infoContainer}>
    <SEO title="Your browser is your real digital workplace" />
    <Container style={{ marginBottom: '160px' }}>
      <ContentHeroNew
        annoucement
        headline={
          <span className="title">
            Your browser is your <span className="title-highlight">real</span> digital workplace
          </span>
        }
        // description="That is why we will focus entirely on the development of the web version of Basaas in the future."
      />

      <div className="hero-img">
        <StaticImage src="../../../assets/images/the-browser-is-your-real-digital-workplace.png" alt="Your browser is your real digital workplace" placeholder="blurred" />
      </div>
      <p className="info-body">
        Our all-new Chrome Extension brings all the features you love directly in your browser. Your Basaas Sidebar with your frequently used apps runs now in your browser und you can use it on every
        website. With the new Basaas browser version we offer you the best digital workplace solution currently available on the market.
      </p>

      <p className="info-body strong">
        To be able to develop our browser version in the best possible way, we will focus on web development in the future and stop further development of our Basaas App starting from the end of
        September.
      </p>

      <div className="cta-container">
        <a
          className="linkBtn cta"
          href="https://www.basaas.com/blog/the-browser-is-the-real-digital-workplace?utm_source=basaas-app&utm_medium=annoucement&utm_campaign=app-migration"
          target="_blank"
          rel="noreferrer"
        >
          Read more in our related blog post
        </a>
      </div>

      <p className="info-box">
        We will support all our customers to migrate to the Basaas browser version. This is necessary because the Basaas App will only be available for a transition period. If you are looking for
        support for your migration, please contact us at <a href="mailto:support@basaas.com">support@basaas.com</a>.
      </p>
    </Container>

    {/* <ContentBanner
      headline="Productivity superpowers for your browser"
      description="Check out the Basaas Chrome Extension"
      actionLinkText="Try Basaas Chrome Extension today"
      actionLinkIsExternal
      actionLinkInNewWindow
      actionLink="https://chrome.google.com/webstore/detail/basaas-extension/dfnefpadkibojlglpobnjbphlaojobfg?utm_source=basaas-com&utm_medium=banner&utm_campaign=extension&utm_content=productivity-superpowers"
      // media={<StaticImage src="../../assets/images/app-icons.svg" alt="Optimize your app management" placeholder="blurred" />}
    /> */}
  </div>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
