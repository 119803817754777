// extracted by mini-css-extract-plugin
export const infoContainer = "index-module__infoContainer--33d-O";
export const title = "title";
export const titleHighlight = "title-highlight";
export const heroImg = "hero-img";
export const infoBody = "info-body";
export const strong = "strong";
export const ctaContainer = "cta-container";
export const linkBtn = "linkBtn";
export const cta = "cta";
export const infoBox = "info-box";